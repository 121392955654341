import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactQuill from 'react-quill';
import { toast } from 'react-toastify';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { DialogContent, Tooltip } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';

import TextInput from '../../common/components/TextInput';
import DropDownTextInput from '../../common/components/DropDownTextInput';
import Loader from '../../common/components/Loader';
import { reactQuillFormats, reactQuillModules } from '../../common/functions';
import agent from '../../agent';
import {
  AssignmentType,
  ERROR_MSG,
  taskStatus,
  taskTypes,
} from '../../constants/otherConstants';

import {
  GET_COURSE_ASSIGNMENTS,
  GET_ASSIGNMENTS_TASK_TAGS,
} from '../../constants/actionTypes';
import './Courses.scss';
import CreateTaskHint from './CreateTaskHint';
import CreateReviewQuestion from './CreateReviewQuestion';
import CreateTestCases from './CreateTestCases';
import { Link } from 'react-router-dom';
import { pathNames } from '../../constants/navigationPath';
import CategoriesSelect from './CategoriesSelect';
import CreateBoilerplateCode from './CreateBoilerplateCode';
import { getUniqueTags } from '../../constants/helperFunctions';
import { Science } from '@mui/icons-material';
import UploadCodePackage from './UploadCodePackage';
const CreateTask = (props) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [task, setTask] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [error, setError] = useState(null);
  const [showTaskHints, setShowTaskHints] = useState(false);
  const [showTaskReviewQuestion, setShowTaskReviewQuestion] = useState(false);
  const [tagText, setTagText] = useState('');
  const [currentTaskTags, setCurrentTaskTags] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const taskSelectRef = useRef();

  const courseAssignments = useSelector(
    (state) => state.CoursesReducers.courseAssignments
  );
  const dispatch = useDispatch();

  const assignmentTaskTags = useSelector(
    (state) => state.AssignmentsReducers.allAssignmentTaskTags
  );

  useEffect(() => {
    if (props.isUpdate) {
      setIsUpdate(props.isUpdate);
    }
  }, []);

  useEffect(() => {
    if (props.task && props.task !== task) {
      setTask(props.task);
    }
    if (props.task && props.task.AssignmentTaskTags) {
      let tempTags = props.task.AssignmentTaskTags.map((task) => {
        return { tag: task.tag, type: task.type };
      });
      setCurrentTaskTags(tempTags);
    }

    if (props.task && props.task.Categories) {
      setSelectedCategories(props.task.Categories);
    }
  }, [props.task]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTaskHintDialogOpen = () => {
    setShowTaskHints(true);
  };

  const handleTaskDialogClose = () => {
    setShowTaskHints(false);
  };

  const handleTaskReviewQuestionClose = () => {
    setShowTaskReviewQuestion(false);
  };
  const handleDescriptionChange = (value) => {
    setTask({ ...task, description: value });
  };
  const handleTasktagsChange = (value) => {
    setCurrentTaskTags(value);
    setTask({ ...task, tags: value });
    setTagText('');
  };

  const validateTask = (task) => {
    if (task.isTimeBounded && !task.unitTime) {
      setError('*Please select a valid time unit');
      return false;
    }

    return true;
  };

  const createTask = async (e) => {
    if (e) {
      e.preventDefault();
    }

    if (!validateTask(task)) {
      return;
    }
    setLoading(true);

    //add the new tags to the store
    handleTasktagsChange(currentTaskTags);
    if (task.tags) {
      let tempAssignmentTaskTags = [
        ...getUniqueTags([...assignmentTaskTags, ...task.tags]),
      ];

      dispatch({
        type: GET_ASSIGNMENTS_TASK_TAGS,
        payload: tempAssignmentTaskTags,
      });
    }

    let newTask = { ...task };
    if (currentTaskTags.length !== 0) {
      newTask.AssignmentTaskTags = currentTaskTags;
    }
    // const categoryId = selectedCategories.map((c) => c.id);
    // newTask.Categories = categoryId;

    setTask(newTask);

    if (currentTaskTags && currentTaskTags.length === 0) {
      toast.error('Please select atleast a tag');
      setLoading(false);
      return;
    }

    let tempAllAssignments;
    try {
      if (isUpdate) {
        const res = await agent.Assignments.updateTask(
          { ...newTask, Categories: selectedCategories.map((c) => c.id) },
          newTask.id
        );

        tempAllAssignments = courseAssignments?.map((a) => {
          if (a.id === props.AssignmentId) {
            const tasks = a.AssignmentTasks.map((t) => {
              if (t.id === res.data.data?.id) {
                return { ...t, ...newTask };
              } else {
                return t;
              }
            });
            return { ...a, AssignmentTasks: tasks };
          }
          return a;
        });
      } else {
        const tempTask = {
          AssignmentId: props.AssignmentId,
          ...newTask,
          TaskHints: [],

          AssignmentTaskReviewQuestions: [],
        };

        const res = await agent.Assignments.createTask(tempTask);

        tempAllAssignments = courseAssignments?.map((a) => {
          if (a.id === props.AssignmentId) {
            const tasks = [
              ...a.AssignmentTasks,
              {
                ...res.data.data,
                TaskHints: [],
                AssignmentTaskTags: currentTaskTags,
                BoilerPlateCodes: [],
                AssignmentTaskReviewQuestions: [],
              },
            ];
            return { ...a, AssignmentTasks: tasks };
          }
          return a;
        });

        setTask({ description: '' });
        toast.success(
          'Task created successfully and moved to archive tab. Admin will review it and publish it soon.',
          {
            autoClose: 10000,
          }
        );

        props.moveToArchiveTab();
      }

      // After creating or updating task updating assignment to store total task points
      // const updatedAssignment = await agent.Assignments.updateAssignment(
      //   {},
      //   props.AssignmentId
      // );

      // tempAllAssignments = tempAllAssignments?.map((a) => {
      //   if (a.id === props.AssignmentId) {
      //     return {
      //       ...a,
      //       ...updatedAssignment.data.data,
      //     };
      //   }
      //   return a;
      // });

      dispatch({ type: GET_COURSE_ASSIGNMENTS, payload: tempAllAssignments });
      setOpen(false);
    } catch (err) {
      toast.error(ERROR_MSG);
    }

    setLoading(false);
  };

  const selectTaskType = (type) => {
    if (!type) {
      setError('Please select the task type');
      return;
    }
    setTask({ ...task, type });
  };

  const SimpleTaskDialog = () => {
    const {
      name,
      description,
      points,
      taskIndex,
      nextButtonText,
      videoUrl,
      type,
      isTimeBounded,
      unitTime,
      maximumTime,
    } = task;

    return (
      <Dialog onClose={handleClose} open={open} maxWidth={'lg'} fullWidth>
        <DialogTitle>Create Task</DialogTitle>
        <DialogContent>
          <form onSubmit={createTask} className="create-task-form">
            <TextInput
              label="Title"
              value={name}
              required
              onTextChange={(value) => setTask({ ...task, name: value })}
              disabled={task.isLockedForEdit}
            />
            <TextInput
              label="Points"
              value={points}
              type="number"
              required
              onTextChange={(value) => setTask({ ...task, points: value })}
              disabled={task.isLockedForEdit}
            />
            <TextInput
              label="Task index"
              type="number"
              value={taskIndex}
              required
              onTextChange={(value) => setTask({ ...task, taskIndex: value })}
              disabled={task.isLockedForEdit}
            />
            {props.assignment.type === AssignmentType.Default && (
              <>
                <TextInput
                  label="Primary video url"
                  type="text"
                  value={videoUrl}
                  onTextChange={(value) =>
                    setTask({ ...task, videoUrl: value })
                  }
                  disabled={task.isLockedForEdit}
                />
                <TextInput
                  label="Next button text"
                  type="text"
                  value={nextButtonText}
                  onTextChange={(value) =>
                    setTask({ ...task, nextButtonText: value })
                  }
                  disabled={task.isLockedForEdit}
                />
                <div className="flex items-center justify-between">
                  <p>Is task time bounded:</p>
                  <Switch
                    checked={isTimeBounded}
                    onChange={() =>
                      setTask({ ...task, isTimeBounded: !isTimeBounded })
                    }
                    disabled={task.isLockedForEdit}
                  />
                </div>
                {task.isTimeBounded && (
                  <div className="flex items-center justify-between mt2 mb2">
                    <TextInput
                      label="Maximum Time"
                      type="number"
                      className="w-80 mr2"
                      value={maximumTime}
                      required
                      onTextChange={(value) =>
                        setTask({ ...task, maximumTime: value })
                      }
                    />
                    <select
                      onChange={(e) =>
                        setTask({ ...task, unitTime: e.target.value })
                      }
                      value={unitTime}
                      required
                      disabled={task.isLockedForEdit}
                    >
                      <option value={''}> Select type </option>
                      <option value="Minutes">Minutes </option>
                      <option value="Hours"> Hours </option>
                      <option value="Days"> Days</option>
                    </select>
                  </div>
                )}
              </>
            )}
            <select
              onChange={(e) => selectTaskType(e.target.value)}
              ref={taskSelectRef}
              value={type}
              disabled={task.isLockedForEdit}
              required
            >
              <option value={''}> Select type </option>
              {Object.keys(taskTypes).map((k, i) => (
                <option key={i} value={taskTypes[k]}>
                  {' '}
                  {taskTypes[k]}{' '}
                </option>
              ))}
            </select>

            <p className="mt2 mb2">Tags:</p>
            <DropDownTextInput
              label="Enter tag"
              data={assignmentTaskTags}
              onChangeText={(e) => setTagText(e)}
              setOption={(value) => handleTasktagsChange(value)}
              tags={currentTaskTags}
              value={tagText}
              required={true}
              isUpdate={task.isLockedForEdit}
            />
            <CategoriesSelect
              selectedCategories={selectedCategories}
              setSelectedCategories={setSelectedCategories}
              isUpdate={task.isLockedForEdit}
            />

            <p className="mt2 mb2">Task Description:</p>
            <ReactQuill
              defaultValue={description}
              onChange={handleDescriptionChange}
              modules={reactQuillModules}
              formats={reactQuillFormats}
              readOnly={task.isLockedForEdit}
            />
            {error && <p className="error"> {error} </p>}
            <div className="btns-div">
              {!isUpdate && (
                <button type="submit" className="secondary-btn w-100 mt2">
                  Create Task
                </button>
              )}
              {isUpdate && !task.isLockedForEdit && (
                <button type="submit" className="secondary-btn w-100 mt2">
                  Update Task
                </button>
              )}
            </div>
          </form>
        </DialogContent>
        {loading && <Loader />}
      </Dialog>
    );
  };

  const TaskHintsDialog = () => {
    const { TaskHints } = task;

    return (
      <Dialog
        onClose={handleTaskDialogClose}
        open={showTaskHints}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Assignment Tasks: {task.name}</DialogTitle>
        <DialogContent>
          <CreateTaskHint isUpdate={false} task={task} />

          <table>
            <tr>
              <th className="w-70">Hint</th>
              <th>Points To Redeem</th>
              {/* <th>Locked</th> */}
            </tr>
            {TaskHints &&
              TaskHints.map((taskHint, i) => (
                <CreateTaskHint
                  isUpdate={true}
                  taskHint={taskHint}
                  index={i}
                  key={task.id}
                  task={task}
                  setTask={setTask}
                />
              ))}
          </table>
        </DialogContent>
      </Dialog>
    );
  };
  const TaskReviewQuestionDialog = () => {
    const { AssignmentTaskReviewQuestions } = task;

    return (
      <Dialog
        onClose={handleTaskReviewQuestionClose}
        open={showTaskReviewQuestion}
        maxWidth
      >
        <DialogTitle>Assignment Task Review Question</DialogTitle>
        <DialogContent>
          <CreateReviewQuestion isUpdate={false} task={task} />
          <div className="task-table">
            <table className="content">
              <tr>
                <th>Question</th>
              </tr>
              {AssignmentTaskReviewQuestions &&
                AssignmentTaskReviewQuestions.map((reviewQuestion) => (
                  <CreateReviewQuestion
                    isUpdate={true}
                    reviewQuestion={reviewQuestion}
                    key={task.id}
                    task={task}
                    setTask={setTask}
                  />
                ))}
            </table>
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <>
      {isUpdate ? (
        <tr className="task">
          <td>
            <button type="button" onClick={handleClickOpen}>
              {task.taskIndex}
            </button>
          </td>
          <td>
            <button type="button" onClick={handleClickOpen}>
              {task.name}
            </button>
          </td>
          <td>
            <button type="button" onClick={handleClickOpen}>
              {task.points}
            </button>
          </td>
          <td>
            <button type="button" onClick={handleClickOpen}>
              {task.maximumTime} {task.unitTime}
            </button>
          </td>
          <td>
            <div className="flex items-center">
              <button type="button" onClick={handleClickOpen}>
                {task.type}
              </button>
              {task.type === taskTypes.coding && (
                <div className="flex justify-center items-center">
                  <CreateTestCases task={task} />
                  <CreateBoilerplateCode task={task} setTask={setTask} />
                </div>
              )}
              {(task.type === taskTypes.reactCompiler ||
                task.type === taskTypes.nodeCompiler ||
                task.type === taskTypes.htmlWebsite) && (
                <div className="flex justify-center items-center">
                  <UploadCodePackage task={task} />
                </div>
              )}
              {task.type === taskTypes.cssCompiler && (
                <Link
                  to={`${pathNames.ADD_CSS_TASK_INPUT_OUTPUT}?status=${task?.status}&AssignmentTaskId=${task.id}`}
                  target="_blank"
                >
                  <button
                    type="button"
                    onClick={handleTaskHintDialogOpen}
                    className="secondary-btn"
                  >
                    {task?.status === taskStatus.LIVE
                      ? 'See Testcases'
                      : 'Create TestCases'}
                  </button>
                </Link>
              )}

              {task.type === taskTypes.mcq && (
                <Link
                  to={`${pathNames.TASK_QUESTIONS}?TaskId=${task.id}&&AssignmentId=${task.AssignmentId}`}
                  target="_blank"
                  className="secondary-btn"
                >
                  See questions
                </Link>
              )}
              {task.type === taskTypes.sql && (
                <Tooltip title="Add Test cases">
                  <Link
                    to={`${pathNames.CREATE_SQL_TEST_CASES}?TaskId=${task.id}&&AssignmentId=${task.AssignmentId}`}
                    target="_blank"
                  >
                    <Science
                      style={{ color: 'skyblue' }}
                      onClick={handleClickOpen}
                    />
                  </Link>
                </Tooltip>
              )}
            </div>
          </td>
          <td>
            <div className="flex items-center">
              {task.isLockedForEdit ? 'Yes' : 'No'}
            </div>
          </td>
          <td>
            <button
              type="button"
              className="task-btn"
              onClick={handleTaskHintDialogOpen}
            >
              See tasks Hints
            </button>
          </td>
        </tr>
      ) : (
        <button
          type="button"
          className="secondary-btn mt2 mb2 ml-auto"
          onClick={handleClickOpen}
        >
          Create Task
        </button>
      )}

      {SimpleTaskDialog()}
      {TaskHintsDialog()}
      {TaskReviewQuestionDialog()}
    </>
  );
};

export default CreateTask;
