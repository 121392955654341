import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ReactQuill from 'react-quill';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { DialogContent } from '@material-ui/core';

import TextInput from '../../common/components/TextInput';
import Loader from '../../common/components/Loader';
import agent from '../../agent';
import { reactQuillFormats, reactQuillModules } from '../../common/functions';

import { GET_COURSE_ASSIGNMENTS } from '../../constants/actionTypes';
import './Courses.scss';
import { taskStatus } from '../../constants/otherConstants';

const CreateTaskHint = (props) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [taskHint, setTaskHint] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (props.isUpdate) {
      setIsUpdate(props.isUpdate);
    }
  }, []);

  useEffect(() => {
    if (props.taskHint && props.taskHint !== taskHint) {
      setTaskHint(props.taskHint);
    }
  }, [props.taskHint]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validateTask = (taskHint) => {
    if (!taskHint.hint) {
      setError('*Please add the hint descreption');
      return false;
    }
    return true;
  };
  const createTaskHint = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (!validateTask(taskHint)) {
      return;
    }
    setLoading(true);

    if (isUpdate) {
      agent.Assignments.updateTaskHint(taskHint)
        .then(() => {
          const { courseAssignments, setCourseAssignments, task } = props;
          const tempAllAssignments = courseAssignments?.map((a) => {
            if (a.id === task.AssignmentId) {
              const tasks = a.AssignmentTasks.map((t) => {
                if (t.id === task.id) {
                  const tempTaskHint = t.TaskHints.map((hint) => {
                    if (hint.id === taskHint.id) {
                      return taskHint;
                    }
                    return hint;
                  });

                  return { ...t, TaskHints: tempTaskHint };
                }
                return t;
              });
              return { ...a, AssignmentTasks: tasks };
            }
            return a;
          });

          setCourseAssignments(tempAllAssignments);
          setLoading(false);
          setOpen(false);
        })
        .catch((err) => {
          console.log(err, err.response);
          setLoading(false);
        });
    } else {
      const tempTask = { AssignmentTaskId: props.task.id, ...taskHint };

      agent.Assignments.createTaskHint(tempTask)
        .then((res) => {
          const { courseAssignments, setCourseAssignments, task } = props;
          const tempAllAssignments = courseAssignments?.map((a) => {
            if (a.id === task.AssignmentId) {
              const tasks = a.AssignmentTasks.map((t) => {
                if (t.id === task.id) {
                  const tempTask = {
                    ...t,
                    TaskHints: [...t.TaskHints, res.data.data],
                  };
                  return tempTask;
                }
                return t;
              });
              return { ...a, AssignmentTasks: tasks };
            }
            return a;
          });
          setCourseAssignments(tempAllAssignments);
          setLoading(false);
          setOpen(false);
          setTaskHint({ description: '' });
        })
        .catch((err) => {
          console.log(err, err.response);
          setLoading(false);
        });
    }
  };
  const deleteTaskHint = () => {
    setLoading(true);
    agent.Assignments.deleteTaskHint({ id: taskHint.id })
      .then(() => {
        const { courseAssignments, setCourseAssignments, task } = props;
        setLoading(false);

        setOpen(false);
        const tempAllAssignments = courseAssignments?.map((a) => {
          if (a.id === task.AssignmentId) {
            const tasks = a.AssignmentTasks.map((t) => {
              if (t.id === task.id) {
                const tempTask = {
                  ...t,
                  TaskHints: t.TaskHints.filter(
                    (hint) => hint.id !== taskHint.id
                  ),
                };
                return tempTask;
              }
              return t;
            });
            return { ...a, AssignmentTasks: tasks };
          }
          return a;
        });
        setCourseAssignments(tempAllAssignments);
      })
      .catch((err) => {
        console.log(err, err.response);
        setLoading(false);
      });
  };

  const SimpleTaskDialog = () => {
    const { hint, pointsToRedeem } = taskHint;
    return (
      <Dialog onClose={handleClose} open={open} maxWidth>
        <DialogTitle>Create Task Hint</DialogTitle>
        <DialogContent>
          <form onSubmit={createTaskHint} className="create-task-form">
            <p className="mt2 mb2">Hint Description:</p>
            <ReactQuill
              defaultValue={hint}
              onChange={(value) => setTaskHint({ ...taskHint, hint: value })}
              modules={reactQuillModules}
              formats={reactQuillFormats}
              readOnly={props.task.status == taskStatus.LIVE && isUpdate}
            />
            <TextInput
              label="Points"
              value={pointsToRedeem}
              type="number"
              required
              onTextChange={(value) =>
                setTaskHint({ ...taskHint, pointsToRedeem: value })
              }
              disabled={props.task.status == taskStatus.LIVE && isUpdate}
            />
            {error && <p className="error"> {error} </p>}
            {(!props?.task?.isLockedForEdit ||
              props.task.status === taskStatus.ARCHIVE) && (
              <div className="btns-div">
                <button type="submit">
                  {' '}
                  {isUpdate ? 'Update Task Hint' : 'Create Task Hint'}{' '}
                </button>
                {isUpdate && (
                  <button
                    type="button"
                    className="red-btn"
                    onClick={() => deleteTaskHint()}
                  >
                    {' '}
                    Delete Task Hint{' '}
                  </button>
                )}
              </div>
            )}
          </form>
        </DialogContent>
        {loading && <Loader />}
      </Dialog>
    );
  };

  return (
    <>
      {isUpdate ? (
        <tr className="taskHint">
          <td>
            <button type="button" onClick={handleClickOpen}>{`Hint ${
              props.index + 1
            }`}</button>
          </td>
          <td>
            <button type="button" onClick={handleClickOpen}>
              {taskHint.pointsToRedeem}
            </button>
          </td>
        </tr>
      ) : (
        <>
          {(!props?.task?.isLockedForEdit ||
            props.task.status === taskStatus.ARCHIVE) && (
            <button
              type="button"
              className="secondary-btn mt2 mb2 ml-auto"
              onClick={handleClickOpen}
            >
              Create Task Hint
            </button>
          )}
        </>
      )}

      {SimpleTaskDialog()}
    </>
  );
};

const mapStateToProps = (state) => ({
  courseAssignments: state.CoursesReducers.courseAssignments,
});

const mapDispatchToProps = (dispatch) => ({
  setCourseAssignments: (assignments) =>
    dispatch({ type: GET_COURSE_ASSIGNMENTS, payload: assignments }),
});
export default connect(mapStateToProps, mapDispatchToProps)(CreateTaskHint);
