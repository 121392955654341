import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { DialogContent } from '@material-ui/core';

import Loader from '../../common/components/Loader';

import agent from '../../agent';

import { availableLanguages, ERROR_MSG } from '../../constants/otherConstants';

import './Courses.scss';
import './Compiler.scss';

import { convertToLink } from '../../common/functions';
import { getErrorMessage } from '../../constants/helperFunctions';

const UploadCodePackage = (props) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [task, setTask] = useState({});
  const [codePackage, setCodePackage] = useState();

  useEffect(() => {
    if (props.task && props.task !== task) {
      setTask(props.task);
    }
  }, [props.task]);

  useEffect(() => {
    if (task && task.BoilerPlateCodes && task.BoilerPlateCodes.length) {
      setCodePackage(task.BoilerPlateCodes[0]);
    }
  }, [task]);
  console.log('Task: ', task);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const uploadCodePackage = async (event) => {
    const file = event.target.files[0];

    const details = {
      codepackage: file,
    };

    const data = Object.keys(details).map((k) => ({
      key: k,
      file: details[k],
    }));

    try {
      setLoading(true);
      const queryObj = {
        AssignmentId: task.id,
        languageId: 1,
      };
      const res = await agent.Assignments.uploadBoilerPlateCodePackage(
        `?AssignmentTaskId=${task.id}&languageId=${1}`,
        data
      );

      setTask({
        ...task,
        BoilerPlateCodes: [...task.BoilerPlateCodes, res.data.data],
      });
      toast.success('Code package uploaded successfully');
    } catch (err) {
      toast.error(getErrorMessage(err));
    }
    setLoading(false);
  };

  const downloadCodePackage = async () => {
    setLoading(true);
    try {
      const response = await agent.Assignments.getCodePackage(
        `?id=${codePackage.id}`
      );

      const fileName = `${task.name}-code-package.zip`;
      convertToLink(response.data, true, fileName);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const SimpleTaskDialog = () => {
    return (
      <Dialog onClose={handleClose} open={open} maxWidth="lg" fullScreen>
        <DialogTitle>
          <div className="flex items-center justify-between">
            <h3>Upload code package for {task.name}</h3>
            <button type="button" className="red-btn f6 " onClick={handleClose}>
              Close
            </button>
          </div>
        </DialogTitle>
        <DialogContent>
          <input type="file" onChange={(e) => uploadCodePackage(e)} />
          {codePackage && (
            <button className="primary-btn mt3" onClick={downloadCodePackage}>
              Download uploaded package
            </button>
          )}
        </DialogContent>
        {loading && <Loader />}
      </Dialog>
    );
  };

  return (
    <>
      <button
        type="button"
        className="ml2 f6 secondary-btn pa2 mt2 mb2"
        onClick={handleClickOpen}
      >
        Upload package
      </button>
      {SimpleTaskDialog()}
    </>
  );
};

export default UploadCodePackage;
